import React from "react"
import { Paragraph } from "grommet"
import styled from "styled-components"

export default styled(Paragraph)`
  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 1.53333;
  }
`
