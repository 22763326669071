import React from "react"
import { Box } from "grommet"
import styled from "styled-components"

export default styled(Box)`
  padding: ${p => p.theme.global.edgeSize.large}
    ${p => p.theme.global.edgeSize.xlarge}
    ${p => (p.paddingBottom ? p.paddingBottom : p.theme.global.edgeSize.large)}
    ${p => p.theme.global.edgeSize.xlarge};
  @media (max-width: 411px) {
    padding: ${p => p.theme.global.breakpoints.small.edgeSize.medium}
      ${p => p.theme.global.breakpoints.small.edgeSize.large};
  }
`
