import React from "react"

import ReviewSuccess from "../components/ReviewSuccess"
import Layout from "../components/Layout"
import SEO from "../components/seo"

const ThankYouPage = ({ search }) => (
  <Layout>
    <SEO title="Thank You" />

    <ReviewSuccess />
  </Layout>
)

export default ThankYouPage
