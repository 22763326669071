import React from "react"
import { Heading } from "grommet"
import styled from "styled-components"

const StyledHeading = styled(Heading)`
  text-transform: ${p => p.theme.heading.font.transform || "none"};
  text-rendering: optimizeLegibility;
`

const BDHeading = ({ children, ...rest }) => (
  <StyledHeading {...rest}>{children}</StyledHeading>
)

export default BDHeading
