import React from "react"
import { Button } from "grommet"

import Heading from "./Heading"
import Paragraph from "./Paragraph"
import ResponsiveInnerPad from "./ResponsiveInnerPad"

const ReviewSuccess = () => (
  <ResponsiveInnerPad>
    <Heading level={1} margin={{ bottom: "small" }}>
      Thanks so much!
    </Heading>
    <Paragraph>
      As a thank you for your review, a donation of $5 will be  made to our
      charity partner the International Rescue Committee.
    </Paragraph>
  </ResponsiveInnerPad>
)

export default ReviewSuccess
